<template>
  <div>
    <h1>ข้อมูลการตรวจซ้อนคัน</h1>
    <br />
    <div class="innfobox">
      <div class="card" style="width: 100%" v-if="roundInfoData != null">
        <div class="card">
          <div class="card-horizontal">
            <div class="img-square-wrapper">
              <img
                v-img
                style="width: 240px"
                :src="roundInfoData.member_verify_photo"
                class="img-fluid card-img-top"
                :key="'x'"
              />
            </div>
            <div class="card-body card-info">
              <h4 class="card-title">
                <b
                  >{{ roundInfoData.member_name }}
                  <span v-if="roundInfoData.is_cancel" style="color: #ae1a1a"
                    >(ยกเลิกการตรวจ)</span
                  ></b
                >
              </h4>
              <br />
              <p class="card-text">
                <b>เวลา</b> {{ roundInfoData.create_date | formatDate }}
              </p>
              <p class="card-text">
                <b>เวลาเริ่มตรวจ</b>
                {{ roundInfoData.walk_start_datetime | formatDate }}
              </p>
              <p class="card-text">
                <b>เวลาเสร็จสิ้นการตรวจ</b>
                {{ roundInfoData.walk_end_datetime | formatDate }}
              </p>
            </div>
          </div>
          <div class="card-footer"></div>
        </div>
      </div>
    </div>
    <br />
    <vs-tabs>
      <vs-tab label="รถจอดเกินสิทธิ์">     
        <div class="con-tab-ejemplo">             
          <vs-card style="margin-top: 10px; padding: 0px">
            <vs-table stripe :data="car_over_lap">
              <template slot="thead">
                <vs-th class="no-col">ลำดับ</vs-th>
                <vs-th class="house-data-col">ข้อมูลลูกบ้าน</vs-th>
                <vs-th></vs-th>
              </template>
              <template>
                <vs-tr
                  class="map-list-table"
                  :key="index"
                  v-for="(trr, index) in car_over_lap"
                  style="cursor: pointer"                  
                >
                  <vs-td class="no-col">
                    {{ index + 1 }}
                  </vs-td>
                  <vs-td class="house-data-col">
                    บ้านเลขที่ : {{ car_over_lap[index].info.house_no }}<br />
                    ชื่อ : {{ car_over_lap[index].info.name }}
                    <br />
                    เบอร์โทร : {{ car_over_lap[index].info.phone }}
                  </vs-td>
                  <vs-td>
                    <vs-table stripe :data="car_over_lap[index].rows">
                    <template slot="thead">
                      <vs-th class="car-col">เลขทะเบียน</vs-th>
                      <vs-th class="detail-col">รายละเอียด</vs-th>
                      <vs-th class="time-in-col">เวลาตรวจ</vs-th>
                      <vs-th class="detail-col">รูปแนบ</vs-th>
                    </template>
                    <template>
                      <vs-tr
                        class="map-list-table"
                        :key="i"
                        v-for="(trr, i) in car_over_lap[index].rows"
                        style="cursor: pointer">
                        <vs-td class="car-col">
                          {{ car_over_lap[index].rows[i].car_number }}
                        </vs-td>
                        <vs-td class="detail-col">
                          {{
                            car_over_lap[index].rows[i].desc | checkString
                          }}
                        </vs-td>
                        <vs-td class="time-in-col">
                          {{
                            car_over_lap[index].rows[i].create_date
                              | formatDate
                          }}
                        </vs-td>     
                        <vs-td class="img-ul time-in-col">
                          <ul
                            v-if="
                              Array.isArray(car_over_lap[index].rows[i].photos
                              ) &&
                              car_over_lap[index].rows[i].photos.length >
                                0
                            "
                          >
                            <li
                              :key="indextr_image"
                              v-for="(image, indextr_image) in car_over_lap[index].rows[i].photos"
                            >
                              <img
                                v-img
                                style="width: 40px"
                                :src="image"
                                class="img-fluid"
                                :key="indextr_image"
                              />
                            </li>
                          </ul>
                          <ul v-else>
                            -
                          </ul>
                          </vs-td>                   
                      </vs-tr>
                    </template>
                  </vs-table>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-card>
        </div>
      </vs-tab>

      <vs-tab label="รถจอดตามสิทธิ์">     
        <div class="con-tab-ejemplo">                   
          <vs-card style="margin-top: 10px; padding: 0px">
            <vs-table stripe :data="car_house">
              <template slot="thead">
                <vs-th class="no-col">ลำดับ</vs-th>
                <vs-th class="house-data-col">ข้อมูลลูกบ้าน</vs-th>
                <vs-th></vs-th>
              </template>
              <template>
                <vs-tr
                  class="map-list-table"
                  :key="index"
                  v-for="(trr, index) in car_house"
                  style="cursor: pointer"                  
                >
                  <vs-td class="no-col">
                    {{ index + 1 }}
                  </vs-td>
                  <vs-td class="house-data-col">
                    บ้านเลขที่ : {{ car_house[index].info.house_no }}<br />
                    ชื่อ : {{ car_house[index].info.name }}
                    <br />
                    เบอร์โทร : {{ car_house[index].info.phone }}
                  </vs-td>
                  <vs-td>
                    <vs-table stripe :data="car_house[index].rows">
                    <template slot="thead">
                      <vs-th class="car-col">เลขทะเบียน</vs-th>
                      <vs-th class="detail-col">รายละเอียด</vs-th>
                      <vs-th class="time-in-col">เวลาตรวจ</vs-th>
                      <vs-th class="detail-col">รูปแนบ</vs-th>
                    </template>
                    <template>
                      <vs-tr
                        class="map-list-table"
                        :key="i"
                        v-for="(trr, i) in car_house[index].rows"
                        style="cursor: pointer">
                        <vs-td class="car-col">
                          {{ car_house[index].rows[i].car_number }}
                        </vs-td>
                        <vs-td class="detail-col">
                          {{
                            car_house[index].rows[i].desc | checkString
                          }}
                        </vs-td>
                        <vs-td class="time-in-col">
                          {{
                            car_house[index].rows[i].create_date
                              | formatDate
                          }}
                        </vs-td>     
                        <vs-td class="img-ul time-in-col">
                          <ul
                            v-if="
                              Array.isArray(car_house[index].rows[i].photos
                              ) &&
                              car_house[index].rows[i].photos.length >
                                0
                            "
                          >
                            <li
                              :key="indextr_image"
                              v-for="(image, indextr_image) in car_house[index].rows[i].photos"
                            >
                              <img
                                v-img
                                style="width: 40px"
                                :src="image"
                                class="img-fluid"
                                :key="indextr_image"
                              />
                            </li>
                          </ul>
                          <ul v-else>
                            -
                          </ul>
                          </vs-td>                   
                      </vs-tr>
                    </template>
                  </vs-table>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-card>
        </div>
      </vs-tab>
      <vs-tab label="รถไม่มีในระบบ">
        <div class="con-tab-ejemplo">          
          <vs-card style="margin-top: 10px; padding: 0px">
            <vs-table stripe :data="car_not_within">
              <template slot="thead">
                <vs-th class="no-col">ลำดับ</vs-th>
                <vs-th class="car-col">ทะเบียนรถ</vs-th>
                <vs-th class="time-in-col">เวลาตรวจ</vs-th>
                <vs-th class="detail-col">รายละเอียด</vs-th>
                <vs-th class="detail-col">รูปแนบ</vs-th>
              </template>
              <template>
                <vs-tr
                  class="map-list-table"
                  :key="index"
                  v-for="(trr, index) in car_not_within"
                  style="cursor: pointer"
                  v-on:click.native="
                    toggleInfoWindow(car_not_within[index], index)
                  "
                >
                  <vs-td class="no-col">
                    {{ index + 1 }}
                  </vs-td>
                  <vs-td class="car-col">
                    {{ car_not_within[index].car_number }}
                  </vs-td>
                  <vs-td class="time-in-col">
                    {{ car_not_within[index].create_date | formatDate }}
                  </vs-td>
                  <vs-td class="detail-col">
                    {{ car_not_within[index].desc }}
                  </vs-td>
                  <vs-td class="img-ul">
                    <ul
                      v-if="
                        Array.isArray(car_not_within[index].photos) &&
                        car_not_within[index].photos.length > 0
                      "
                    >
                      <li
                        :key="indextr_image"
                        v-for="(image, indextr_image) in car_not_within[index]
                          .photos"
                      >
                        <img
                          v-img
                          style="width: 40px"
                          :src="image"
                          class="img-fluid"
                          :key="indextr_image"
                        />
                      </li>
                    </ul>
                    <ul v-else>
                      -
                    </ul>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-card>
        </div>
      </vs-tab>
      <vs-tab label="รถผู้มาติดต่อ">
        <div class="con-tab-ejemplo">          
          <vs-card style="margin-top: 10px; padding: 0px">
            <vs-table stripe :data="car_visitor">
              <template slot="thead">
                <vs-th class="no-col">ลำดับ</vs-th>
                <vs-th class="car-col">ทะเบียนรถ</vs-th>
                <vs-th class="car-col">ติดต่อ</vs-th>
                <vs-th class="time-in-col">เวลาเข้า</vs-th>
                <vs-th class="time-in-col">เวลาตรวจ</vs-th>
                <vs-th class="detail-col">รายละเอียด</vs-th>
                <vs-th class="detail-col">รูปแนบ</vs-th>
              </template>
              <template>
                <vs-tr
                  class="map-list-table"
                  :key="index"
                  v-for="(trr, index) in car_visitor"
                  style="cursor: pointer"
                  v-on:click.native="
                    toggleInfoWindow(car_visitor[index], index)
                  "
                >
                  <vs-td class="no-col">
                    {{ index + 1 }}
                  </vs-td>
                  <vs-td class="car-col">
                    {{ car_visitor[index].car_number }}
                  </vs-td>
                  <vs-td class="car-col">
                    {{ car_visitor[index].contact_address_name }}
                  </vs-td>
                  <vs-td class="time-in-col">
                    {{ car_visitor[index].date_in | formatDate }}
                  </vs-td>
                  <vs-td class="time-in-col">
                    {{ car_visitor[index].create_date | formatDate }}
                  </vs-td>
                  <vs-td class="detail-col">
                    {{
                      car_visitor[index].desc | checkString
                    }}
                  </vs-td>
                  <vs-td class="img-ul">
                    <ul
                      v-if="
                        Array.isArray(car_visitor[index].photos) &&
                        car_visitor[index].photos.length > 0
                      "
                    >
                      <li
                        :key="indextr_image"
                        v-for="(image, indextr_image) in car_visitor[index]
                          .photos"
                      >
                        <img
                          v-img
                          style="width: 40px"
                          :src="image"
                          class="img-fluid"
                          :key="indextr_image"
                        />
                      </li>
                    </ul>
                    <ul v-else>
                      -
                    </ul>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-card>
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import VueImg from "v-img";

Vue.use(VueCookies);
Vue.use(VueImg);

Vue.filter("formatDate", function (value) {
  if (value) {
    const myDate = new Date(value.seconds * 1000); // date object
    return moment(String(myDate)).format("DD/MM/YYYY HH:mm:ss");
  }
  return "-";
});

Vue.filter("checkString", function (value) {
  if (value) return value;
  return "-";
});

export default {
  name: "GoogleMap",
  data() {
    return {
      checkpointOrderList: [],
      roundInfoData: null,
      round_id: "",
      markers: [],
      car_over_lap: [],
      car_house: [],
      car_visitor: [],
      car_not_within: [],
      currentPlace: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      infoContent: "",
    };
  },

  mounted() {},
  async created() {
    this.round_id = new URL(location.href).searchParams.get("id");
    this.roundInfo();
    await this.getCheckPointOrder();
    await this.listItems();
  },
  methods: {
    replaceText: function (value, withValue) {
      if (value === null || value === "" || value === undefined) {
        return withValue;
      }
      return value;
    },
    roundInfo() {
      const customerName = $cookies.get("customer");
      const _ducumentPath = `customer/${customerName}/scan/data/transaction_park/`;
      db.collection("customer")
        .doc(customerName)
        .collection("scan")
        .doc("data")
        .collection("transaction_park")
        .doc(this.round_id)
        .get()
        .then((value) => {
          this.roundInfoData = value.data();
        });
    },

    async getCheckPointOrder() {
      const customerName = $cookies.get("customer");
      const _ducumentPath =
        `customer/${customerName}/scan/data/transaction_park/` +
        this.round_id +
        `/transaction_car`;
      await db
        .collection(_ducumentPath)
        // .orderBy("order", "desc")
        .get()
        .then((document) => {
          document.forEach((doc) => {
            this.checkpointOrderList[this.checkpointOrderList.length] =
              doc.data();
          });
        });
    },

    dbRef() {
      const customerName = $cookies.get("customer");
      const _ducumentPath =
        `customer/${customerName}/scan/data/transaction_park/` +
        this.round_id +
        `/transaction_car`;
      return db.collection(_ducumentPath);
    },

    async listItems() {
      await this.dbRef().onSnapshot(async (snapshotChange) => {
        const items = []

        await snapshotChange.forEach(async (doc) => {
          const data = doc.data();
          items[items.length] = data;          
        });
        for (let i = 0 ; i < items.length ; i++) {

          let item = items[i]
          
          if(item.status_type === 1 || item.status_type === 2  || item.status_type === 3){
            // status_type = 1 รถลูกบ้าน จอดได้
            // status_type = 2 รถลูกบ้าน จอดเกินสิทธิ์
            // status_type = 3 รถลูกบ้าน ที่ไม่ได้ตรวจ
            items[i].path = item.house_ref.path;
          } else if(item.status_type === 4 || item.status_type === 5 ){
            // status_type = 4 visitor ที่ได้ตรวจ
            // status_type = 5 visitor ที่ไม่ได้ตรวจ
            items[i].path = item.transactions_ref.path;
          } else if(item.status_type === 6 ){
             // status_type = 6 ไม่มีในระบบ
          }
        }

         // status_type = 2 รถลูกบ้าน จอดเกินสิทธิ์
        let car_invalid_list = items.filter(obj=> {return obj.status_type === 2});
        car_invalid_list = car_invalid_list.sort((a, b) => a.create_date - b.create_date)
        const house_car_invalid_list = [...new Set(car_invalid_list.map(item => item.path))];
        
        let car_over_lap = this.car_over_lap;
        for( let i = 0 ; i < house_car_invalid_list.length ; i++ ){
          const path = house_car_invalid_list[i]
            
            let houseInfo = (await db.doc(path).get()).data();
            if( this.isEmpty(houseInfo)) houseInfo = {};
            let rowData = {
              "info" : houseInfo,
              "rows" : car_invalid_list.filter(obj=> {return obj.path === path})
            };
            car_over_lap[car_over_lap.length] = rowData;            
        }
        console.log("this.car_over_lap=", this.car_over_lap)       
        Vue.set(this.car_over_lap, car_over_lap);

        // status_type = 1 รถลูกบ้าน จอดได้
        let car_valid_list = items.filter(obj=> {return obj.status_type === 1});
        car_valid_list = car_valid_list.sort((a, b) => a.create_date - b.create_date)
        console.log("this.car_valid_list=", car_valid_list)
        const house_car_valid_list = [...new Set(car_valid_list.map(item => item.path))];
        console.log("this.house_car_valid_list=", house_car_valid_list)

        let car_house = this.car_house;
        for( let i = 0 ; i < house_car_valid_list.length ; i++ ){
          const path = house_car_valid_list[i]
            
            let houseInfo = (await db.doc(path).get()).data();
            if( this.isEmpty(houseInfo)) houseInfo = {};
            let rowData = {
              "info" : houseInfo,
              "rows" : car_valid_list.filter(obj=> {return obj.path === path})
            };                        
            car_house[car_house.length] = rowData;
        }
        console.log("this.car_house=", this.car_house)
        Vue.set(this.car_house, car_house);

         // status_type = 6 ไม่มีในระบบ
        this.car_not_within = items.filter(obj=> {return obj.status_type === 6});
        this.car_not_within = this.car_not_within.sort((a, b) => a.create_date - b.create_date)

        // status_type = 4 visitor ที่ได้ตรวจ
        let car_vistitor_list = items.filter(obj=> {return obj.status_type === 4});
        let car_visitor = this.car_visitor;

        for( let i = 0 ; i < car_vistitor_list.length ; i++ ){
            const path = car_vistitor_list[i].path

            let transInfo = (await db.doc(path).get()).data();
            if( this.isEmpty(transInfo)) transInfo = {};

            let rowData = {...car_vistitor_list[i], ...transInfo};
            let car_visitor = this.car_visitor;

            car_visitor[car_visitor.length] = rowData;            
        }
        console.log("this.car_visitor=", this.car_visitor)
        car_visitor = car_visitor.sort((a, b) => a.create_date - b.create_date)
        Vue.set(this.car_visitor, car_visitor);
      });
    },

    isEmpty(data) {
      return data === undefined || data === null || data === "";
    },

    getOrder: function (name) {
      for (let i = 0; i < this.checkpointOrderList.length; i++) {
        const item = this.checkpointOrderList[i];
        if (item.name === name) return item.order;
      }

      return 0;
    },
    getMarker: function (item) {
      let iconName = require("../assets/images/pin/red.svg");

      if (item.is_ok === true) {
        iconName = require("../assets/images/pin/green.svg");
      }

      return {
        url: iconName,
        scaledSize: { width: 36, height: 36 },
      };
    },
    toggleInfoWindow: function (marker, idx) {
      console.log("toggleInfoWindow");
      this.infoWindowPos = marker.position;
      this.infoContent = this.getInfoWindowContent(marker);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    getInfoWindowContent: function (marker) {
      return `<div class="card">
  <!--<div class="card-image">
    <figure class="image is-4by3">
      <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
    </figure>
  </div>-->
  <div class="card-content">
    <div class="media">
      <div class="media-content">
        <p class="title is-4">${marker.name}</p>
      </div>
    </div>
    <div class="content">
      ${marker.description}
      <br>
      <time datetime="2016-1-1">${marker.date_build}</time>
    </div>
  </div>
</div>`;
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    geolocate: function () {},
  },
};
</script>

<style>
.card-info {
  padding: 12px;
  margin-left: 10px;
  width: 100%;
  font-size: 18px;
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.fullscreen-v-img {
  z-index: 9999999999999 !important;
}

.img-ul ul li {
  position: relative;
  border: 2px solid rgb(143, 141, 141);
  display: inline-block;
  text-align: center;
  margin-left: 6px;
}

.img-ul ul {
  text-align: left;
}
</style>

<style scoped>
.no-col {
  width: 60px;
  border: 3px solid #ffffff;
}

.no-black-col {
  width: 60px;
  border: 3px solid #000000;
}

.house-col {
  width: 80px;
  border: 3px solid #ffffff;
}

.house-data-col {
  width: 200px;
  border: 3px solid #ffffff;
  vertical-align: top;
}

.name-col {
  width: 180px;
  border: 3px solid #ffffff;
}

.phone-col {
  width: 160px;
  border: 3px solid #ffffff;
}

.car-col {
  width: 100px;
  border: 3px solid #ffffff;
}

.detail-col {
  width: 180px;
  border: 3px solid #ffffff;
}

.time-in-col {
  width: 200px;
  border: 3px solid #ffffff;
}

.map-list-table td {
  min-height: 100px !important;
}

.vue-map-container {
  border: solid 6px #f1eded;
}
.notNorlmalCase {
  background-color: #c3252a !important;
  color: #fff;
  min-width: 100px;
}

.norlmalCase {
  background-color: #009f22 !important;
  color: #fff;
  min-width: 150px;
}
</style>
